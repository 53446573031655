<template>
  <div id="zones-list" class="card card-custom card-stretch">
    <div class="card-header">
      <h3 class="card-title font-weight-bolder text-dark">Zone</h3>
      <div class="card-toolbar">
        <div class="dropdown dropdown-inline">
          <b-button variant="primary" size="sm" v-on:click="$emit('add')">Adaugă</b-button>
        </div>
      </div>
    </div>

    <div class="card-body">
      <Zone
        v-for="zone in zones"
        v-bind:key="zone.id"
        v-bind:zone="zone"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import Zone from "@/view/pages/zones/Zone.vue";

export default {
  components: {
    Zone,
  },

  props: {
    zones: {
      type: Array,
      required: true,
    },
  },

  data() {
    return { };
  },
};
</script>

<style lang="scss">
#zones-list {
  .card-header {
    border: 0;
  }

  .card-body {
    height: 610px;
    overflow-y: scroll;
    padding: 1rem 1rem 1rem 2.25rem;

    .zone {
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }

  .card-footer {

  }
}
</style>
