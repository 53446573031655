import lodash from "lodash";

import circleToPolygon from "circle-to-polygon";
import GeoJSON from "ol/format/GeoJSON";
import MultiPolygon from "ol/geom/MultiPolygon";
import { toLonLat, fromLonLat } from "ol/proj";
import { getDistance } from "ol/sphere";

const geoJsonFormat = new GeoJSON();

export function createCircularPolygon(center, radius, projection) {
  const lonLat  = toLonLat(center, projection);
  const polygon = circleToPolygon(lonLat, radius, 128);

  for (let i = 0; i < polygon.coordinates.length; i++) {
    for (let j = 0; j < polygon.coordinates[i].length; j++) {
      polygon.coordinates[i][j] = fromLonLat(polygon.coordinates[i][j], projection);
    }
  }

  return geoJsonFormat.readGeometry(polygon);
}

export function getNearestPointOfPolygon(polygon, fromPoint, projection) {
  let polygons;

  if (polygon instanceof MultiPolygon) {
    polygons = polygon.getPolygons();
  } else {
    polygons = [ polygon ];
  }

  const fromPointLonLat = toLonLat(fromPoint, projection);

  const allCoords    = polygons.map(polygon => polygon.getCoordinates());
  const flatCoords   = lodash.flattenDepth(allCoords, 2);
  const lonLatCoords = flatCoords.map(coords => toLonLat(coords, projection));
  const distances    = lonLatCoords.map(coords => ({ coords, distance: getDistance(fromPointLonLat, coords) }));
  const furthest     = lodash.minBy(distances, "distance");

  return fromLonLat(furthest.coords, projection);
}


export function getFurthestPointOfPolygon(polygon, fromPoint, projection) {
  let polygons;

  if (polygon instanceof MultiPolygon) {
    polygons = polygon.getPolygons();
  } else {
    polygons = [ polygon ];
  }

  const fromPointLonLat = toLonLat(fromPoint, projection);

  const allCoords    = polygons.map(polygon => polygon.getCoordinates());
  const flatCoords   = lodash.flattenDepth(allCoords, 2);
  const lonLatCoords = flatCoords.map(coords => toLonLat(coords, projection));
  const distances    = lonLatCoords.map(coords => ({ coords, distance: getDistance(fromPointLonLat, coords) }));
  const furthest     = lodash.maxBy(distances, "distance");

  return fromLonLat(furthest.coords, projection);
}
