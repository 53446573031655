<template>
  <div id="zones-map">
    <Map ref="map" v-bind:zoom="zoom" v-on:mounted="mapMounted">
      <vl-interaction-select
        v-if="!drawing"
        ident="modify"
        v-bind:filter="filterSelection"
        v-bind:toggle-condition="() => false"
        v-on:unselect="featureDeselected"
      />

      <!-- Existing zones -->
      <vl-layer-vector v-bind:z-index="2">
        <vl-source-vector ident="zones" v-bind:features="features" />
        <vl-style-func v-bind:factory="styleZone" />
      </vl-layer-vector>

      <vl-interaction-modify
        v-if="!drawing"
        source="modify"
        v-on:modifyend="featureModified"
      />

      <!-- Drawing new zones -->
      <vl-layer-vector v-bind:z-index="3">
        <vl-source-vector ident="draw" v-bind:features.sync="drawnFeatures" />
      </vl-layer-vector>

      <vl-interaction-draw v-if="drawing && !drawingFinished" source="draw" type="polygon" />
      <vl-interaction-modify v-if="drawing" source="draw" />
      <vl-interaction-snap v-if="drawing" source="draw" v-bind:priority="10" />
    </Map>

    <div class="drawing-message">
      <b-alert class="drawing-catchment" fade v-bind:show="drawingCatchment">
        Desenează zona de acoperire.

        <b-button-group size="sm">
          <b-button variant="danger" v-on:click="cancelDrawing">Renunță</b-button>
        </b-button-group>
      </b-alert>
    </div>

    <div class="drawing-message">
      <b-alert class="drawing-catchment" fade v-bind:show="drawingExtraCatchment && !drawingFinished">
        Desenează zona de acoperire suplimentară pentru
        <span v-if="extraCatchmentZone" class="font-weight-bold">{{ extraCatchmentZone.name }}.</span>

        <b-button-group size="sm">
          <b-button variant="danger" v-on:click="cancelDrawing">Renunță</b-button>
        </b-button-group>
      </b-alert>
    </div>

    <div class="drawing-message">
      <b-alert class="drawing-finished" fade v-bind:show="drawingFinished">
        Zona este completă.

        <b-button-group size="sm">
          <b-button variant="primary" v-on:click="saveZone">Salvează</b-button>
          <b-button variant="danger" v-on:click="cancelDrawing">Renunță</b-button>
        </b-button-group>
      </b-alert>
    </div>
  </div>
</template>

<script>
import jQuery from "jquery";
import lodash from "lodash";

import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import { toLonLat, fromLonLat } from "ol/proj";
import MultiPolygon from "ol/geom/MultiPolygon";
import { createStyle } from "vuelayers/lib/ol-ext";

import { createCircularPolygon, getNearestPointOfPolygon, getFurthestPointOfPolygon } from "@/core/geo";
import { default as Map, wkt } from "@/view/content/widgets/map/MapBase.vue";

const cityCenter = fromLonLat([ 23.807373, 44.319315 ]);

const geoJsonFormat = new GeoJSON();

function getStyleForZone(feature) {
  const zone  = feature.get("zone");
  const color = feature.get("color");
  const rgb   = color.slice(0, 3);
  const alpha = color[3];

  let style = {
    fillColor: color,

    strokeColor: [ ...rgb, alpha * 0.5 ],
    strokeWidth: 3,
  };

  if (feature.get("catchment")) {
    style = {
      ...style,

      text: zone.name,
      textFont: "Poppins, Helvetica, 'sans-serif'",
      textFontSize: "8pt",

      textFillColor: [ 0, 0, 255 ],
      textStrokeColor: [ 0, 0, 255, 0.25 ],
      textBackgroundFillColor: [ 255, 255, 255 ],
      textBackgroundStrokeColor: [ 0, 0, 255 ],

      textPadding: [ 1, 2, 0, 2 ],
      textOffsetY: -20,
    };
  }

  return createStyle(style);
}

function convertGeoJsonToWkt(geoJson) {
  const feature = geoJsonFormat.readFeature(geoJson);

  return wkt.writeGeometry(feature.getGeometry());
}

function createFeature(zone, geometry, color, properties) {
  const feature = new Feature({ zone, geometry, color });

  feature.setProperties(properties);

  return geoJsonFormat.writeFeatureObject(feature);
}

function createFeaturesForZone(zone) {
  const catchmentGeo     = wkt.readGeometry(zone.catchment);
  const catchmentFeature = createFeature(zone, catchmentGeo, zone.catchmentColor, { catchment: true });

  const radiusMeters = zone.radius * 1000;

  const furthestCenter  = getFurthestPointOfPolygon(catchmentGeo, cityCenter);
  const furthestGeo     = createCircularPolygon(furthestCenter, radiusMeters);
  const furthestFeature = createFeature(zone, furthestGeo, zone.radiusColor, { radius: radiusMeters });

  const nearestCenter  = getNearestPointOfPolygon(catchmentGeo, cityCenter);
  const nearestGeo     = createCircularPolygon(nearestCenter, radiusMeters);
  const nearestFeature = createFeature(zone, nearestGeo, zone.radiusColor, { radius: radiusMeters });

  return {
    catchment: catchmentFeature,
    furthest: furthestFeature,
    nearest: nearestFeature,
  };
}

function createZoneFromFeatures(features) {
  const catchmentWkt = convertGeoJsonToWkt(features.catchment);

  return {
    catchment: catchmentWkt,
  };
}

function updateZoneWithFeature(zone, feature) {
  const geoJson = geoJsonFormat.writeFeatureObject(feature);
  const wkt     = convertGeoJsonToWkt(geoJson);

  if (feature.get("catchment")) {
    zone.catchment = wkt;
  }
}

function appendFeatureToZoneCatchment(zone, feature) {
  const newGeometry      = geoJsonFormat.readFeature(feature).getGeometry();
  const existingGeometry = wkt.readGeometry(zone.catchment);

  let polygons;

  if (existingGeometry instanceof MultiPolygon) {
    polygons = existingGeometry.getPolygons();
  } else {
    polygons = [ existingGeometry ];
  }

  polygons.push(newGeometry);

  zone.catchment = wkt.writeGeometry(new MultiPolygon(polygons));
}

export default {
  components: {
    Map,
  },

  props: {
    zones: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      zoom: 15,

      drawingZone: false,
      drawingExtraCatchment: false,
      drawnFeatures: [],

      extraCatchmentZone: null,
    };
  },

  computed: {
    features() {
      const visible  = this.zones.filter(zone => zone.visible);
      const features = visible.map(createFeaturesForZone)
                              .map(zone => [ zone.furthest, zone.nearest, zone.catchment ]);

      // Catchment and radius are drawn in this order to make the latter more visible.
      return lodash.compact(lodash.flatten(features));
    },

    drawing() {
      return this.drawingZone || this.drawingExtraCatchment;
    },

    drawingCatchment() {
      return this.drawingZone && this.drawnFeatures.length === 0;
    },

    drawingFinished() {
      switch (this.drawnFeatures.length) {
        case 1:
          return true;

        default:
          return false;
      }
    },
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    startDrawingZone() {
      this.drawingZone = true;
    },

    startDrawingExtraCatchment(zone) {
      this.drawingExtraCatchment = true;
      this.extraCatchmentZone    = zone;
    },

    cancelDrawing() {
      this.drawingZone           = false;
      this.drawingExtraCatchment = false;

      this.extraCatchmentZone = null;

      this.drawnFeatures = [];
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    mapMounted() {
      const formHeight = jQuery("#zones-list").height();
      const mapHeight  = Math.max(formHeight, 680);

      this.$refs.map.resize(mapHeight);
    },

    featureModified(event) {
      event.features.forEach(feature => {
        feature.setProperties({ modified: true });
      });
    },

    featureDeselected(feature) {
      if (feature.get("modified")) {
        const zone = feature.get("zone");

        updateZoneWithFeature(zone, feature);

        this.$emit("zone-modified", zone);
      }
    },

    saveZone() {
      if (this.drawingZone) {
        const features = {
          catchment: this.drawnFeatures[0],
        };

        const zone = createZoneFromFeatures(features);

        this.cancelDrawing();
        this.$emit("zone-drawn", zone);
      } else if (this.drawingExtraCatchment) {
        const zone       = this.extraCatchmentZone;
        const newFeature = this.drawnFeatures[0];

        appendFeatureToZoneCatchment(zone, newFeature);

        this.cancelDrawing();
        this.$emit("zone-modified", zone);
      }
    },

    styleZone() {
      return getStyleForZone;
    },

    filterSelection(feature) {
      // Only zone catchment features are selectable.
      return !!feature.get("catchment");
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/_mixins.scss";

#zones-map {
  position: relative;

  .drawing-message {
    top: 1rem;
    left: 50%;
    position: absolute;

    pointer-events: none;

    .alert {
      left: -50%;
      position: relative;

      .btn-group {
        margin-left: 0.5rem;

        button {
          pointer-events: auto;
        }
      }
    }

    .drawing-catchment {
      border-color: transparentize($color: $primary, $amount: 0.15);
      background-color: transparentize($color: $primary, $amount: 0.15);
    }

    .drawing-finished {
      border-color: transparentize($color: $success, $amount: 0.15);
      background-color: transparentize($color: $success, $amount: 0.15);
    }
  }
}
</style>
