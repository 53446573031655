<template>
  <b-modal
    centered
    size="lg"

    cancel-title="Renunță"
    cancel-variant="danger"

    v-model="visible"
    v-bind:title="title"
    v-bind:ok-title="okTitle"
    v-bind:ok-disabled="$v.$invalid"

    v-on:hide="hidden"
  >
    <b-row>
      <b-col cols="4">
        <b-form-group label="Nume zonă:">
          <b-form-input
            id="zone-name"
            aria-describedby="zone-name-feedback"

            v-model="$v.zone.name.$model"
            v-bind:state="state.zone.name"
            v-bind:trim="true"
          />

          <b-form-invalid-feedback id="zone-name-feedback">
            <span v-if="!$v.zone.name.required">Numele zonei este obligatoriu.</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="4">
        <b-form-group label="Durata licitației:">
          <b-input-group append="sec">
            <b-form-input
              id="zone-duration"
              aria-describedby="zone-duration-feedback"
              type="number"

              v-model="$v.zone.duration.$model"
              v-bind:state="state.zone.duration"
              v-bind:number="true"
              v-bind:no-wheel="true"
              v-bind:min="minDuration"
              v-bind:max="maxDuration"
            />
          </b-input-group>

          <b-form-invalid-feedback
            id="zone-duration-feedback"
            v-bind:style="{ display: $v.zone.duration.$anyError ? 'block' : 'none' }"
          >
            <span v-if="!$v.zone.duration.minValue">Durata licitației trebuie să fie de cel puțin {{ minDuration | seconds }}.</span>
            <span v-else-if="!$v.zone.duration.maxValue">Durata licitației trebuie să fie de cel mult {{ maxDuration | seconds }}.</span>
            <span v-else-if="!$v.zone.duration.required">Durata licitației este obligatorie.</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="4">
        <b-form-group label="Raza zonei:">
          <b-input-group append="km">
            <b-form-input
              id="zone-radius"
              aria-describedby="zone-radius-feedback"
              type="number"

              v-model="$v.zone.radius.$model"
              v-bind:state="state.zone.radius"
              v-bind:number="true"
              v-bind:no-wheel="true"
              v-bind:min="minRadius"
              v-bind:max="maxRadius"
            />
          </b-input-group>

          <b-form-invalid-feedback
            id="zone-radius-feedback"
            v-bind:style="{ display: $v.zone.radius.$anyError ? 'block' : 'none' }"
          >
            <span v-if="!$v.zone.radius.minValue">Raza zonei trebuie să fie de cel puțin {{ minRadius }} km.</span>
            <span v-else-if="!$v.zone.radius.maxValue">Raza zonei trebuie să fie de cel mult {{ maxRadius }} km.</span>
            <span v-else-if="!$v.zone.radius.required">Raza zonei este obligatorie.</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group label="Timpi licitație:">
      <b-input-group append="min">
        <b-form-tags
          input-id="zone-options"
          v-bind:input-attrs="{ 'aria-describedby': 'zone-options-feedback' }"

          separator=" ,;"
          add-button-text="Adaugă"
          add-button-variant="primary"
          remove-on-delete
          tag-pills
          tag-class="font-size-lg"
          tag-remove-label="Șterge timp"
          tag-removed-label="Timp șters"
          tag-variant="primary"

          invalid-tag-text=""
          duplicate-tag-text=""

          v-model="$v.zone.options.$model"
          v-bind:state="state.zone.options"
          v-bind:trim="true"
          v-bind:placeholder="zoneOptionsPlaceholder"
          v-bind:tag-validator="zoneOptionValidator"

          v-on:tag-state="zoneOptionsState"
        />
      </b-input-group>

      <b-form-invalid-feedback
        id="zone-options-feedback"
        v-bind:style="{ display: $v.zone.options.$anyError ? 'block' : 'none' }"
      >
        <span v-if="!$v.zone.options.unique">Ați introdus același timp de două ori.</span>
        <span v-else-if="!$v.zone.options.numeric">Trebuie să introduceți un număr de minute.</span>
        <span v-else-if="!$v.zone.options.minValue">Trebuie să introduceți un timp de cel puțin {{ minDuration | minutes }}.</span>
        <span v-else-if="!$v.zone.options.maxValue">Trebuie să introduceți un timp de cel mult {{ maxDuration | minutes }}.</span>
        <span v-else-if="!$v.zone.options.required">Trebuie să introduceți cel puțin un timp de licitație.</span>
      </b-form-invalid-feedback>
    </b-form-group>
  </b-modal>
</template>

<script>
import moment from "moment";

import { validationMixin } from "vuelidate";
import { minValue, maxValue, required } from "vuelidate/lib/validators";
import { isNullOrEmpty } from "@/core/utils";

const minDuration = 1;
const maxDuration = 30;

const minRadius = 1;
const maxRadius = 50;

const minOption = 1;
const maxOption = 30;

function mapValidationState(state) {
  if (state.$dirty) {
    if (state.$error) {
      return false;
    }

    if (!isNullOrEmpty(state.$model)) {
      return true;
    }
  }

  return null;
}

export default {
  filters: {
    seconds: function (seconds) {
      return seconds === 1 ? "o secundă" : seconds + " secunde";
    },

    minutes: function (minutes) {
      return minutes === 1 ? "un minut" : minutes + " minute";
    },
  },

  mixins: [ validationMixin ],

  data() {
    return {
      zone: {
        name:     null,
        duration: null,
        radius:   null,
        options:  [],
      },

      zoneOptionsInvalid: [],
      zoneOptionsDuplicate: [],

      minDuration,
      maxDuration,

      minRadius,
      maxRadius,

      visible: false,
      updating: false,

      resolve: null,
      reject:  null,
    };
  },

  validations: {
    zone: {
      name: {
        required,
      },

      duration: {
        required,
        minValue: minValue(minDuration),
        maxValue: maxValue(maxDuration),
      },

      radius: {
        required,
        minValue: minValue(minRadius),
        maxValue: maxValue(maxRadius),
      },

      options: {
        required,
        unique:  function() { return this.zoneOptionsDuplicate.length === 0; },
        numeric: function() { return this.zoneOptionsInvalid.every(opt => Number.isInteger(Number.parseInt(opt, 10))); },
        minValue: function() { return this.zoneOptionsInvalid.every(opt => opt >= minOption); },
        maxValue: function() { return this.zoneOptionsInvalid.every(opt => opt <= maxOption); },
      },
    },
  },

  computed: {
    title() {
      return this.updating ? `Modifică zona ${this.zone.name}` : "Adaugă o nouă zonă";
    },

    okTitle() {
      return this.updating ? "Salvează" : "Adaugă";
    },

    state() {
      return {
        zone: {
          name:     mapValidationState(this.$v.zone.name),
          duration: mapValidationState(this.$v.zone.duration),
          radius:   mapValidationState(this.$v.zone.radius),
          options: this.zoneOptionsInvalid.length   > 0 ||
                   this.zoneOptionsDuplicate.length > 0
                     ? false
                     : mapValidationState(this.$v.zone.options),
        },
      };
    },

    zoneOptionsPlaceholder() {
      return this.zone.options.length > 0 ? "" : "Introduceți un timp";
    },
  },

  destroyed() {
    if (this.reject) {
      this.reject(new Error("Dialog destroyed before resolve."));
    }
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    show(zone) {
      if (zone) {
        this.zone = {
          ...zone,

          duration: moment.duration(zone.duration).asSeconds(),
          options:  zone.options
                        .map(opt => moment.duration(opt))
                        .map(opt => opt.asMinutes()),
        };

        this.updating = true;
      } else {
        this.zone = {
          name:     null,
          duration: null,
          radius:   null,
          options:  [],
        };

        this.updating = false;
      }

      this.visible = true;
      this.$v.$reset();

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject  = reject;
      });
    },

    hide() {
      this.resolve = null;
      this.reject  = null;

      this.visible = false;
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    hidden(event) {
      if (event.trigger !== 'ok') {
        return;
      }

      this.$v.$touch();

      if (this.$v.$invalid) return;

      const zone = {
        ...this.zone,

        duration: moment.duration(this.zone.duration, "seconds")
                        .format("H:mm:ss", { trim: false }),

        options: this.zone.options
                          .map(opt => moment.duration(opt, "minutes"))
                          .map(opt => opt.format("H:mm:ss", { trim: false })),
      };

      this.resolve(zone);
    },

    zoneOptionsState(_, invalid, duplicate) {
      this.zoneOptionsInvalid   = invalid;
      this.zoneOptionsDuplicate = duplicate

      if (invalid.length > 0 || duplicate.length > 0) {
        this.$v.zone.options.$touch();
      }
    },

    zoneOptionValidator(option) {
      const number = Number.parseInt(option, 10);

      return number &&
        option >= minOption &&
        option <= maxOption;
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal {
    .modal-dialog {
      .modal-footer {
        justify-content: space-between;
      }
    }
  }
}
</style>
