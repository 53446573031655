<template>
  <div class="zone d-flex align-items-center">
    <span class="bullet bullet-bar align-self-stretch" v-bind:style="{ backgroundColor: buttonColorCss }" />

    <label class="checkbox checkbox-lg flex-shrink-0 m-0 mx-4">
      <input type="checkbox" v-model="visible" />
      <span v-bind:style="{ backgroundColor: buttonColorCss }" />
    </label>

    <div class="d-flex flex-column flex-grow-1">
      <span class="text-dark-75 font-weight-bold font-size-lg mb-1">{{ zone.name }}</span>
    </div>

    <div class="buttons">
      <b-button
        class="btn-icon btn-light btn-hover-info"
        size="sm"
        v-b-tooltip.hover="'Modifică'"
        v-bind:disabled="!zone.visible"
        v-on:click="$emit('edit', zone)"
      >
        <i class="fas fa-edit" />
      </b-button>

      <b-button
        class="btn-icon btn-light btn-hover-primary"
        size="sm"
        v-b-tooltip.hover="'Adaugă zonă de acoperire'"
        v-bind:disabled="!zone.visible"
        v-on:click="$emit('extend', zone)"
      >
        <i class="fas fa-draw-polygon" />
      </b-button>

      <b-button
        class="btn-icon btn-light btn-hover-danger"
        size="sm"
        v-b-tooltip.hover="'Șterge'"
        v-bind:disabled="!zone.visible"
        v-on:click="$emit('remove', zone)"
      >
        <i class="fas fa-trash" />
      </b-button>
    </div>
  </div>
</template>

<script>
function rgba(color) {
  return `rgba(${color.join(",")})`;
}

export default {
  props: {
    zone: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      visible: this.zone.visible,
    };
  },

  computed: {
    buttonColorCss() {
      return rgba(this.zone.buttonColor);
    },

    catchmentColorCss() {
      return rgba(this.zone.catchmentColor);
    },
  },

  watch: {
    visible(value) {
      if (value) {
        this.$emit("visible", this.zone);
      } else {
        this.$emit("hidden", this.zone);
      }
    },
  },
};
</script>

<style lang="scss">
.zone {
  .buttons {
    .btn {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
