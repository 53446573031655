<template>
  <div>
    <b-row>
      <b-col cols="3">
        <List
          ref="list"
          v-bind:zones="zones"
          v-on:add="addZone"
          v-on:edit="editZone"
          v-on:extend="extendZone"
          v-on:visible="showZone"
          v-on:hidden="hideZone"
          v-on:remove="removeZone"
        />
      </b-col>

      <b-col cols="9">
        <Map
          ref="map"
          v-bind:zones="zones"
          v-on:zone-drawn="zoneDrawn"
          v-on:zone-modified="zoneModified"
        />
      </b-col>
    </b-row>

    <EditZoneDialog ref="editZoneDialog" />
  </div>
</template>

<script>
import materialColor from "material-colors";
import parseColor from "parse-color";

import api from "@/core/services/api";

import List from "@/view/pages/zones/List.vue";
import Map from "@/view/pages/zones/Map.vue";

import EditZoneDialog from "@/view/pages/zones/dialogs/EditZone.vue";

var colorIndex = 0;

const rootColors = [
  materialColor.blue,
  materialColor.red,
  materialColor.green,
  materialColor.amber,
  materialColor.purple,
  materialColor.deepOrange,
  materialColor.pink,
  materialColor.teal
];

const indexedColors = [
  ...rootColors.map(color => color[600]),
  ...rootColors.map(color => color.a200),
  ...rootColors.map(color => color[900]),

  materialColor.brown[700],
  materialColor.grey[900]
];

const assignedColors = {};

function getColorForId(id) {
  var color = assignedColors[id];
  if (!color) {
    color = indexedColors[colorIndex++ % indexedColors.length];

    assignedColors[id] = color;
  }

  return parseColor(color).rgb;
}



function mapZone(zone) {
  const color = getColorForId(zone.id);

  return {
    ...zone,

    visible: false,

    buttonColor:    [ ...color, 0.9 ],
    catchmentColor: [ ...color, 0.5 ],
    radiusColor:    [ ...color, 0.3 ],
  };
}



export default {
  name: "Zones",

  components: {
    List,
    Map,
    EditZoneDialog,
  },

  data() {
    return {
      zones: [],
    };
  },

  async mounted() {
    const zones  = await api.zones.list();
    const mapped = zones.map(mapZone);

    this.zones = mapped;

    this.$store.dispatch("setBreadcrumb", [{ title: "Zone limitrofe" }]);
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** List Events ***** ***** ***** ***** ***** \*/
    addZone() {
      this.$refs.map.startDrawingZone();
    },

    async editZone(zone) {
      const details = await this.$refs.editZoneDialog.show(zone);
      const edited  = { ...zone, ...details };

      await this.updateZone(edited);
    },

    extendZone(zone) {
      const index = this.zones.findIndex(s => s.id === zone.id);

      if (index !== -1) {
        this.$refs.map.startDrawingExtraCatchment(zone);
      }
    },

    showZone(zone) {
      zone.visible = true;
    },

    hideZone(zone) {
      zone.visible = false;
    },

    async removeZone(zone) {
      const index = this.zones.findIndex(s => s.id === zone.id);

      if (index !== -1) {
        const response = await this.confirmRemoveZone(zone);

        if (response) {
          await api.zones.delete(zone);

          this.zones.splice(index, 1);
        }
      }
    },

    /*\ ***** ***** ***** ***** ***** Map Events ***** ***** ***** ***** ***** \*/
    async zoneDrawn(drawn) {
      const details = await this.$refs.editZoneDialog.show();
      const request = {
        name:      details.name,
        catchment: drawn.catchment,
        radius:    details.radius,
        duration:  details.duration,
        options:   details.options,
      };

      const created = await api.zones.create(request);
      const mapped  = mapZone(created);

      mapped.visible = true;

      this.zones.push(mapped);
    },

    async zoneModified(zone) {
      this.updateZone(zone);
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    async updateZone(zone) {
      const request = {
        id:        zone.id,
        name:      zone.name,
        catchment: zone.catchment,
        radius:    zone.radius,
        duration:  zone.duration,
        options:   zone.options,
      };

      const updated = await api.zones.update(request);
      const mapped  = mapZone(updated);

      mapped.visible = zone.visible;

      const index = this.zones.findIndex(s => s.id === zone.id);

      if (index !== -1) {
        this.$set(this.zones, index, mapped);
      } else {
        this.zones.push(mapped);
      }
    },

    confirmRemoveZone(zone) {
      const message = this.$createElement("p", { class: [ "mb-0", "font-size-h6", "text-center" ] }, [
        "Sunteți sigur că doriți să ștergeți stația ",
        this.$createElement("strong", zone.name),
        "?"
      ]);

      const options = {
        centered: true,

        okTitle: "Șterge",
        okVariant: "danger",

        cancelTitle: "Renunță",

        footerClass: "justify-content-between",
      };

      return this.$bvModal.msgBoxConfirm(message, options);
    },
  },
};
</script>
